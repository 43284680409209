import React from 'react'
import { Link } from 'gatsby';
import {Card} from '@blueprintjs/core'

class BlogFeatured extends React.Component {

  render() {

    const { title, description, link, author, date } = this.props;

    return (
      <Card className="BlogFeatured" interactive={true}>
        <Link to={link}>
          <div className="grid-row">
            <div className="grid-row grid-column blog-content">
              <div className="blog-title">{title}</div>
              <div className="blog-description">{description}</div>
              <div className="blog-author">
                {author} on {date}
              </div>
            </div>
          </div>
        </Link>
      </Card>
    )
  }

}

export default BlogFeatured;
