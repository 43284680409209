import React from 'react'

class BlogPost extends React.Component {

  render() {

    const { title } = this.props;

    return (
      <section className="BlogPost grid-restrain-narrow grid-row">
        <div className="BlogPost-title">{title}</div>
        <div className="BlogPost-content markdown-blog">{this.props.children}</div>
      </section>
    )
  }

}

export default BlogPost;
