import React from 'react'
import {BlogFeatured} from '../../components/blog'
import {LayoutA} from '../../layouts'

class Blog extends React.Component {

  render() {
    return (
      <LayoutA>
        <div className="BlogHomePage">
          <div className="BlogHomePage-column">
            <BlogFeatured title="Why We Write"
                          image="https://via.placeholder.com/1000"
                          link="/blog/post/our-vision"
                          author="Joseph Jung"
                          date="April 18th, 2019"
                          description="Why we write so much? Six principles that explain our love for writing."/>
            <BlogFeatured title="Why We Write"
                          image="https://via.placeholder.com/1000"
                          link="/blog/post/our-vision"
                          author="Joseph Jung"
                          date="April 18th, 2019"
                          description="Why we write so much? Six principles that explain our love for writing."/>
          </div>
        </div>
      </LayoutA>
    )
  }

}

export default Blog;
